<template>
  <div class="container">
    <app-title title="My account" :underline="true" underline-color="primary" />
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-3 mb-4" v-for="action in actions">
        <app-card text-align="center" @clicked="navigate(action.route)">
          <template #body>
            <i class="bi" :class="`${action.icon}`"></i>
            <p>{{ action.title }}</p>
          </template>
        </app-card>
      </div>
    </div>
    <div class="row activity-title-row">
      <div class="col-12 col-lg-6">
        <app-title
          title="Latest activity"
          :underline="true"
          underline-color="primary"
        />
      </div>
      <div class="col-12 col-lg-6 btn-col">
        <div class="col-12 btn-right">
          <button class="btn-sm-accent" @click="navigate('/u/activity')">
            See all
          </button>
        </div>
      </div>
    </div>
    <div class="row user-activity-row mb-4">
      <div class="col-12 mb-4">
        <h4 class="activity-title mb-4">Latest news submitted</h4>
        <latest-activity
          :items="news"
          collection="news"
          :is-loading="isLoading"
          :show-details="true"
          @update="getData"
          @delete="getData"
        />
      </div>
      <div class="col-12 mb-4">
        <h4 class="activity-title mb-4">Latest dataset submitted</h4>
        <latest-activity
          :items="library"
          collection="library"
          :is-loading="isLoading"
          :show-details="true"
          @update="getData"
          @delete="getData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserMenu from '@/components/user/Menu';
import LatestActivity from '@/components/user/Activity';
import { actions } from '@/utils/config/user';
import Database from '@/utils/services/Database';

export default {
  name: 'UserDashboard',
  components: {
    UserMenu,
    LatestActivity,
  },
  data() {
    return {
      actions,
      news: [],
      library: [],
      isLoading: false,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getActivity({
        type: 'latest_activities',
      });

      this.news = res.news;
      this.library = res.library;
      this.isLoading = false;
    },
    navigate(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-title-row {
  .btn-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.activity-title {
  font-size: 1.1rem;
  font-weight: 500;
}
.card {
  .card-header,
  .card-footer {
    display: none;
  }
  .card-body {
    i {
      font-size: 30px;
      transition: $trs-default;
    }
    p {
      margin-top: $sp;
    }
  }
  &:hover {
    .card-body {
      i {
        color: $danger;
      }
    }
  }
}

@include bp-down(lg) {
  .activity-title {
    display: none;
  }
  .activity-title-row {
    .btn-col {
      margin-bottom: 1rem;
    }
  }
}
</style>
